import React from 'react'

export const ChatLabel = () => {
  return (
    <div className='chat_label_container'>
        <p className='chat_label_paragraph'>Chat</p>

    </div>
  )
}
