import VideoButtons from "./VideoButtons";

const VideoSection = () => {
    return ( 
        <div className="video_section_container">
           <VideoButtons/> 
        </div>
     );
}
 
export default VideoSection;