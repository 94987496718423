import React from 'react';

export const ConversationNotChosen = () => {
  return (
    <div className='conversation_not_chosen_overlay'>
        <p className='conversation_not_chosen_overlay_text'>
            Conversation not chosen
        </p>
    </div>
  )
}
