const Overlay = () => {
    return ( 
        <div className="overlay_container">
            <div className="loader">

            </div>

        </div>
     );
}
 
export default Overlay;